<!--
 * @Author: daipeng
 * @Date: 2019-09-09 15:34:03
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-18 14:06:54
 * @Description: 标签分类
 -->
<template>
    <div class="label-category" v-loading.fullscreen.lock="isLoading">
        <div class="label-category-head">
            <div class="label-category-head-l">
                <h3>标签分类</h3>
                <div v-if="isAdmin" class="label-category-add" @click.stop="appendHandler(undefined)"><i class="el-icon-plus"></i></div>
                <div v-if="isAdmin" class="label-category-desc"><i class="el-icon-info"></i><span>可拖动排序</span></div>
            </div>
            <template v-if="orderList.length && isAdmin">
                <el-button size="mini" type="text" @click="saveOrderHandler">保存排序</el-button>
            </template>
        </div>
        <div class="label-category-tree">
            <el-tree
                ref="tree"
                :data="treeList"
                node-key="id"
                :props="defaultProps"
                :default-expanded-keys="categoryObj.tag_category_ids"
                :render-after-expand="false"
                highlight-current
                @node-click="treeNodeClickHandler"
                @node-drop="dropEndHandler"
                :allow-drop="allowDropHandler"
                :draggable="isAdmin"
                :expand-on-click-node="false">
                <div slot-scope="{ node, data }" class="custom-tree-node" :id="'node' + data.id">
                    <div class="tree-node-title single-ellipsis" :class="data.show_status ? '' : 'disabled'" :title="node.label">{{ node.label }}</div>
                    <div v-if="isAdmin" class="tree-node-opration">
                        <el-button type="text" size="mini" :disabled="!data.show_status" @click.stop="appendHandler(data, node)">
                            <i class="el-icon-plus"></i>
                        </el-button>
                        <el-popover
                            placement="right-start"
                            popper-class="popper-d"
                            trigger="hover"
                            :open-delay="100"
                            width="50">
                            <div style="text-align: center">
                                <el-button size="mini" :disabled="!data.show_status" type="text" @click="editHandler(data, node)">编辑</el-button>
                                <el-button type="text" style="margin-left: 0;color:red" size="mini" @click="disableHandler(data, node)">{{data.show_status ? '禁用' : '启用'}}</el-button>
                            </div>
                            <el-button slot="reference" type="text" size="mini" @click.stop="() => {}">
                                <i class="el-icon-more"></i>
                            </el-button>
                        </el-popover>
                    </div>
                </div>
            </el-tree>
        </div>
        <dialogPlus
            center
            :visible.sync="dialogData.visible"
            :title.sync="dialogData.title"
            width="500px"
            :heightFull="false"
            :close-on-click-modal="false"
        >
            <el-form class="ae-form" label-width="100px" ref="aeForm" :model="dialogData.data">
                <el-form-item label="选择上级分类">
                    <el-cascader
                        :options="cascaderList"
                        :props="cascaderProps"
                        :change-on-select="true"
                        v-model="dialogData.data.ids">
                    </el-cascader>
                </el-form-item>
                <el-form-item prop="category_name" label="分类名" :rules="[{ required: true, message: '分类名为必填！', trigger: 'blur' }]">
                    <el-input v-model="dialogData.data.category_name" placeholder="请输入分类名" clearable></el-input>
                </el-form-item>
                <el-form-item align="center" label-width="0">
                    <el-button size="mini" @click="cancleHandler">取消</el-button>
                    <el-button size="mini" type="primary" @click="confirmHandler">确认</el-button>
                </el-form-item>
            </el-form>
        </dialogPlus>
    </div>
</template>

<script>
import { dialogMixin, formMixin } from '@/mixins';
import { mapState } from 'vuex';

export default {
    mixins: [dialogMixin, formMixin],
    props: {
        categoryNode: { type: Object, default: () => ({ data: { id: null } }) },
        categoryObj: { type: Object, default: () => ({ category_list: [], tag_category_ids: [] }) }
    },
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'category_name',
                disabled: function(data) {
                    return !!data.show_status;
                }
            },
            cascaderProps: {
                value: 'id',
                label: 'category_name',
                children: 'children'
            },
            orderList: [],
            isLoading: false
        };
    },
    computed: {
        treeList() {
            return this.$utils.createTree(this.categoryObj.category_list);
        },
        cascaderList() {
            return [
                { id: 0, category_name: '无', show_status: 1 },
                ...this.treeList.filter(category => category.show_status === 1)
            ];
        },
        ...mapState('app', {
            isAdmin: state => state.userInfo.level > 1
        })
    },
    watch: {
        categoryNode(newValue, oldValue) {
            if (this.$refs.tree && newValue && newValue.data && newValue.data.id) this.$refs.tree.setCurrentKey(newValue.data.id);
        },
        'categoryObj.tag_category_ids'(newValue = [], oldValue) {
            if (!newValue.length) {
                this.$nextTick(() => {
                    this.$refs.tree.$el.querySelectorAll('.searched').forEach(dom => (dom.className = dom._prevClass));
                });
                return;
            };
            const onlyArray = [...new Set(newValue)];
            setTimeout(() => {
                if (onlyArray.length > 1) {
                    onlyArray.forEach(key => {
                        const contentDom = this.$refs.tree.$el.querySelector(`#node${key}`);
                        if (!contentDom || !contentDom.parentElement) return;
                        const parentDom = contentDom.parentElement;
                        parentDom.className = parentDom._prevClass + ' searched';
                    });
                } else {
                    this.$emit('categoryChange', this.$refs.tree.getNode(onlyArray[0]));
                }
            }, 200);
        }
    },
    methods: {
        appendHandler(data = {}, node, event) {
            let ids = [];
            const { id: category_id, show_status } = data;
            if (show_status === 0) return ;
            if (node) ids = this.getParentsNodeId(node);
            else ids = [0];
            this.changeDialog(true, '添加分类', 1, { ids, category_name: '', category_id });
        },
        // 获取祖先分类
        getParentsNodeId(node) {
            let startPrarentId = node.data.parent_id;
            const result = [node.data.id];
            while (startPrarentId !== 0) {
                node = node.parent;
                result.unshift(node.data.id);
                startPrarentId = node.data.parent_id;
            }
            return result;
        },
        // 获取后代分类id
        getChildrenNodeId(node, result = []) {
            result.push(node.data.id);
            if (node.childNodes.length) {
                node.childNodes.forEach(nodeItem => {
                    this.getChildrenNodeId(nodeItem, result);
                });
            }
            return result;
        },
        // 编辑回调
        editHandler(data = {}, node) {
            const { id: category_id, category_name } = data;
            const ids = this.getParentsNodeId(node).slice(0, -1);
            if (!ids.length) ids.push(0);
            this.changeDialog(true, '编辑分类', 2, { ids, category_name, category_id });
        },
        // 获取指定分类涉及的漫画数量
        affectcomiccount(category_id) {
            return this.$api('affectcomiccountByCategory', { category_id }).then(res => {
                return res.data.comic_count;
            });
        },
        // 禁用/启用
        async disableHandler(data, node) {
            const { show_status, category_name, id: category_id } = data;
            const msg = show_status ? '禁用' : '启用';
            const comicCount = await this.affectcomiccount(category_id);
            this.$utils.confirm(`确定${msg}"${category_name}"分类吗？`, `该分类将在所有漫画中被${msg}，有${comicCount}个漫画受到影响`, `${msg}中...`, (action, instance, done) => {
                this.isLoading = true;
                return this.$api('setPubliccategoryStatus', { category_id, show_status: show_status ^ 1 }).then(res => {
                    this.isLoading = false;
                    this.$message({ message: `${msg}"${category_name}"分类成功`, type: 'success' });
                    this.$emit('updatecategory');
                });
            }, { customClass: 'label-dialog' });
        },
        // node点击事件
        treeNodeClickHandler(data, node, vm) {
            // const { show_status } = data;
            // if (!show_status) return ; // 分类禁用也可以展示
            this.$emit('categoryChange', node);
        },
        // 拖拽放置判断
        allowDropHandler(draggingNode, dropNode, type) {
            let result = false;
            if (draggingNode.parent.data.id === dropNode.parent.data.id) {
                result = type !== 'inner';
            }
            return result;
        },
        dropEndHandler(draggingNode, dropNode, type) {
            // 满足拖拽排序需求
            const data = { parent_id: dropNode.parent.data.id || 0, category_ids: [] };
            data.category_ids = this.getChildrenNodeId(dropNode.parent).slice(1);
            const repeatIndex = this.orderList.findIndex(item => item.parent_id === data.parent_id);
            if (repeatIndex > -1) this.orderList.splice(repeatIndex, 1, data);
            else this.orderList.push(data);
        },
        // 保存排序
        saveOrderHandler() {
            this.$utils.confirm('', '确定保存排序吗？', '保存中...', (action, instance, done) => {
                this.isLoading = true;
                return this.$api('resortPubliccategory', { list: this.orderList }).then(res => {
                    this.isLoading = false;
                    this.$message({ message: '排序保存成功', type: 'success' });
                    this.orderList = [];
                    this.$emit('updatecategory');
                });
            });
        },
        // 取消修改
        cancleHandler() {
            this.$refs.aeForm.resetFields();
            this.dialogCloseHandle();
        },
        // 确认修改
        async confirmHandler() {
            const valid = await this.validateForm('aeForm');
            if (!valid) return ;
            const { type, data } = this.dialogData;
            const { category_name, category_id = null, ids } = data;
            const url = type === 1 ? 'addPubliccategory' : 'updatePubliccategory';
            const msg = type === 1 ? '新增' : '修改';
            let submitData = { category_name, parent_id: ids.slice(-1)[0], top_id: ids[0] };
            if (type === 2) submitData.category_id = category_id;
            this.submitHandler(url, submitData, msg);
        },
        // 提交分类 新增、编辑
        submitHandler(url, data, msg) {
            this.$utils.confirm('', `确定${msg}"${data.category_name}"分类吗？`, `${msg}中...`, (action, instance, done) => {
                this.isLoading = true;
                return this.$api(url, data).then(res => {
                    this.isLoading = false;
                    this.$message({ message: `${msg}"${data.category_name}"分类成功`, type: 'success' });
                    this.$emit('updatecategory');
                    this.cancleHandler();
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .label-category{
        height: 100%;
        position: relative;
        &-head {
            line-height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-l{
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        &-add{
            margin-left: 10px;
            width: 16px;
            height: 16px;
            cursor: pointer;
            line-height: 16px;
            text-align: center;
            border: 1px solid #000;
            border-radius: 50%;
        }
        &-tree{
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 6px;
            overflow: auto;
        }
        &-desc{
            color: #ccc;
            margin-left: 10px;
            .el-icon-info{
                font-size: 16px;
                margin-right: 5px;
                vertical-align: middle;
            }
            span{
                vertical-align: middle;
            }
        }
    }
    .custom-tree-node{
        flex: 1 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .tree-node-title{
        flex: 1 0;
        max-width: 200px;
        &.disabled{
            color: #C0C4CC;
        }
    }
    .tree-node-opration{
        margin: 0 10px;
    }
    .ae-form{
        padding: 15px 20px;
    }
</style>
<style lang="scss">
    .popper-d{
        min-width: 50px;
    }
    .label-dialog {
        .el-message-box__title span{
            font-size: 18px;
        }
        .el-message-box__content .el-message-box__message p{
            font-size: 12px;
        }
    }
    .el-tree-node__content.searched{
        background: #F5F7FA;

    }
</style>
