<!--
 * @Author: daipeng
 * @Date: 2019-09-09 11:34:32
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-18 12:05:40
 * @Description: 标签新增编辑
 -->
<template>
    <div class="label-ae">
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="left" :model="searchData" inline @keyup.enter.native.prevent="searchHandle">
            <el-form-item prop="category_status" label="分类状态">
                <el-select v-model="searchData.category_status" style="width:100px" placeholder="选择状态" @change="searchHandle">
                    <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="tag_name" label="标签名">
                <el-input v-model="searchData.tag_name" placeholder="输入标签名" clearable class="search-input"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- search-box-end -->
        <div class="label-content">
            <div class="label-content-col">
                <category
                ref="category"
                :categoryNode="currentCategory"
                :categoryObj="categoryObj"
                @updatecategory="getPublictagsList"
                @categoryChange="categoryChange"
            />
            </div>
            <div class="label-content-col">
                <tagConfig
                    ref="tagConfig"
                    :categoryNode="currentCategory"
                    :categoryList="categoryObj.category_list"
                    @categoryChange="categoryChange"
                    @selectTag="selectTagHandler"
                    @disableTag="disableTagEvent"
                    :resultTagList="resultTagList"
                />
            </div>
            <div class="label-content-col">
                <tagResult
                    ref="tagResult"
                    :selectedTag="selectedTag"
                    :resultTagList.sync="resultTagList"
                />
            </div>
        </div>
        <div class="label-ae-footer">
            <el-button size="mini" @click="cancelHandler">取消</el-button>
            <el-button type="primary" size="mini" @click="saveComicTagHandle">保存</el-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import category from './category';
import tagConfig from './tagConfig';
import tagResult from './tagResult';
import { formMixin } from '@/mixins';

export default {
    components: { category, tagConfig, tagResult },
    mixins: [formMixin],
    data() {
        return {
            statusList: [
                { name: '全部', id: -1 },
                { name: '已启用', id: 1 },
                { name: '已禁用', id: 0 }
            ],
            searchData: {
                category_status: 1,
                tag_name: ''
            },
            categoryObj: {},
            currentCategory: {},
            selectedTag: {},
            resultTagList: []
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level
        })
    },
    created() {
        this.getPublictagsList();
    },
    methods: {
        ...mapMutations('comic', [ 'setTagList' ]),
        // 获取标签分类列表
        getPublictagsList() {
            this.$api('getPubliccategoryList', this.searchData, undefined, undefined, true).then(res => {
                const { data = {} } = res;
                this.categoryObj = data;
                this.currentCategory = {};
                if (this.$refs.tagResult) this.$refs.tagResult.getTagList();
            });
        },
        // 分类选择
        categoryChange(node) {
            node = node || { data: {} };
            const { id } = node.data;
            const { tag_category_ids = [] } = this.categoryObj;
            // 如果当前选中的分类是包含搜索标签的，需要带上搜索标签，如果不包含就不带上直接查全部标签
            node.data.tag_name = tag_category_ids.includes(id) ? this.searchData.tag_name : null;
            this.currentCategory = node;
        },
        // 标签选择
        selectTagHandler(tag) {
            this.selectedTag = tag || {};
        },
        // 搜索
        searchHandle() {
            this.getPublictagsList();
        },
        // 取消事件
        cancelHandler() {
            this.$refs.tagResult.resetTagList();
            this.$emit('cancelDialog');
        },
        // 保存漫画标签
        saveComicTagHandle() {
            const tagList = this.$refs.tagResult.tagList || [];
            // const submit = { comic_id: this.$route.query.comicId, tag_ids: [] };
            // if (tagList.length) {
            //     tagList.forEach(category => {
            //         category.tags.forEach(tag => submit.tag_ids.push(tag.tag_id));
            //     });
            // }

            this.setTagList(tagList);
            this.$emit('saved', tagList);
            // this.$utils.confirm('', `确定保存吗？`, `保存中...`, (action, instance, done) => {
            //     return this.$api('saveComictags', submit).then(res => {
            //         this.$message({ message: '保存成功', type: 'success' });
            //         this.$refs.tagResult.getTagList();
            //         this.$emit('saved');
            //     });
            // });
        },
        // 有标签被禁用、启用
        disableTagEvent(tag) {
            this.getPublictagsList();
            this.$refs.tagResult.localDisable(tag);
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetFormFields('searchForm');
            this.searchHandle();
        },
        // 重置数据
        resetData() {
            this.currentCategory = {};
            this.selectedTag = {};
            this.resultTagList = [];
        }
    }
};
</script>

<style lang="scss" scoped>
    .label-ae{
        box-sizing: border-box;
        height: 100%;
        background: #fff;
        padding: 15px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-flow: column nowrap;
    }
    .label-content{
        padding: 0 15px;
        flex: 1 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        &-col{
            height: 100%;
            width: 33%;
        }
    }
    .label-ae-footer{
        padding: 10px 0;
        text-align: center;
    }
</style>
