<!--
 * @Author: daipeng
 * @Date: 2019-09-10 10:32:46
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-18 11:57:19
 * @Description: 选择标签组件
 -->
<template>
    <div class="tag-config" v-loading.fullscreen.lock="isLoading">
        <div class="tag-config-head">
            <div class="tag-config-head-l">
                <h3>选择标签</h3>
                <div v-if="isAdmin && currentCategoryStatus" class="tag-config-add" @click="appendHandler({})"><i class="el-icon-plus"></i></div>
                <div v-if="isAdmin && currentCategoryStatus" class="tag-config-desc"><i class="el-icon-info"></i><span>双击编辑，拖动排序</span></div>
            </div>
            <template v-if="isOrder && isAdmin">
                <el-button size="mini" type="text" @click="saveOrderHandler">保存排序</el-button>
            </template>
        </div>
        <div class="tag-config-wrap">
            <template v-if="categoryNode.data && categoryNode.data.id">
                <el-breadcrumb class="tag-config-breadcrumb" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item class="breadcrumb-item" v-for="category in breadcrumbList" :key="category.data.id" @click.native="subCategoryClick(category)">{{category.data.category_name}}</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="tag-config-content">
                    <ul v-if="subTreeList.length" class="tag-config-tree">
                        <li :class="{ 'category-child': true, disabled: !category.data.show_status }" v-for="category in subTreeList" :key="category.id" @click="subCategoryClick(category)">
                            <div>{{category.data.category_name}}</div>
                            <div v-if="category.childNodes.length"><i class="el-icon-arrow-right"></i></div>
                        </li>
                    </ul>
                    <div class="tag-config-inner" ref="tagContent">
                        <el-button v-if="isAdmin && currentCategoryStatus" class="button-new-tag" size="mini" @click="appendHandler(categoryNode)">+</el-button>
                        <el-tag
                            :class="{
                                'tag-config-tag': true,
                                'tag-disabled': !tag.show_status,
                                'tag-selected': !tag.show_status ? false : tag.selected
                            }"
                            v-for="tag in tagList" :key="tag.id"
                            :closable="!!tag.show_status && isAdmin"
                            size="medium"
                            :disable-transitions="false"
                            @close="disabledHandler(tag)"
                            @click="selectTagHandler(tag, $event)"
                            @dblclick.native="editHandler(tag, $event)"
                        >
                            <span class="tag-config-tag-inner" :title="tag.tag_name">{{tag.tag_name}}</span>
                            <el-button v-if="!tag.show_status && isAdmin" class="tag-disabled-btn" type="text" size="mini" @click="disabledHandler(tag)">启用</el-button>
                        </el-tag>
                    </div>
                </div>
            </template>
        </div>
        <dialogPlus
            center
            :visible.sync="dialogData.visible"
            :title.sync="dialogData.title"
            width="500px"
            :heightFull="false"
            :close-on-click-modal="false"
        >
            <el-form class="ae-form" label-width="80px" ref="tagForm" :model="dialogData.data">
                <el-form-item label="选择分类" prop="ids" :rules="[{ required: true, type: 'array', message: '标签分类为必填！', trigger: 'change' }]">
                    <el-cascader
                        :options="treeList"
                        :props="cascaderProps"
                        :change-on-select="true"
                        v-model="dialogData.data.ids">
                    </el-cascader>
                </el-form-item>
                <el-form-item prop="tag_name" label="标签名" :rules="[{ required: true, message: '标签名为必填！', trigger: 'blur' }]">
                    <el-input v-model="dialogData.data.tag_name" placeholder="请输入标签名" clearable></el-input>
                </el-form-item>
                <el-form-item align="center" label-width="0">
                    <el-button size="mini" @click="cancleHandler">取消</el-button>
                    <el-button size="mini" type="primary" @click="confirmHandler">确认</el-button>
                </el-form-item>
            </el-form>
        </dialogPlus>
    </div>
</template>

<script>
import { dialogMixin, formMixin } from '@/mixins';
import Sortable from 'sortablejs';
import { mapState } from 'vuex';

export default {
    mixins: [dialogMixin, formMixin],
    props: {
        resultTagList: { type: Array, default: () => [] },
        categoryNode: { type: Object, default: () => ({ data: {} }) },
        categoryList: { type: Array, default: () => [] }
    },
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'category_name',
                disabled: function(data) {
                    return !!data.show_status;
                }
            },
            cascaderProps: {
                value: 'id',
                label: 'category_name',
                children: 'children'
            },
            isOrder: false,
            breadcrumbList: [],
            tagList: [],
            subTreeList: [],
            singleClickTag: false,
            isLoading: false
        };
    },
    created() {

    },
    computed: {
        treeList() {
            return this.$utils.createTree(this.categoryList).filter(category => category.show_status === 1);
        },
        ...mapState('app', {
            isAdmin: state => state.userInfo.level > 1
        }),
        currentCategoryStatus() {
            if (!this.categoryNode || !this.categoryNode.data) return 0;
            return this.categoryNode.data ? this.categoryNode.data.show_status : 0;
        }
    },
    watch: {
        categoryNode(newNode, oldNode) {
            if (newNode && newNode.data && newNode.data.id) {
                this.subTreeList = newNode.childNodes;
                this.breadcrumbList = this.getParentsNodeId(newNode, true);
                const { id, tag_name } = newNode.data;
                this.getTagList(id, tag_name);
            }
        },
        resultTagList(newList, oldList) {
            if (this.tagList.length) this.tagList = this.mergeTagByComicChecked(this.tagList, newList);
        }
    },
    methods: {
        // 获取tag列表
        getTagList(category_id, tag_name) {
            this.$api('getPublictagsList', { category_id, tag_name }).then(res => {
                this.tagList = this.mergeTagByComicChecked(res.data, this.resultTagList);
                this.$nextTick(this.initDrag);
                this.isOrder = false;
            });
        },
        // 合并被漫画选中的标签
        mergeTagByComicChecked(tags = [], resultTagList = []) {
            return tags.map(tag => {
                tag.selected = false;
                if (resultTagList.find(item => tag.id === item.tag_id)) {
                    tag.selected = true;
                }
                return tag;
            });
        },
        // 点击局部tree方法
        treeNodeClickHandler(data, node) {
            this.$emit('categoryChange', node);
        },
        // 获取指定标签涉及的漫画数量
        affectcomiccount(tag_id) {
            return this.$api('affectcomiccountByTag', { tag_id }).then(res => {
                return res.data.comic_count;
            });
        },
        // 禁用、启用标签
        async disabledHandler(tag) {
            const { show_status, id: tag_id, tag_name } = tag;
            const msg = show_status ? '禁用' : '启用';
            const comicCount = await this.affectcomiccount(tag_id);
            this.$utils.confirm(`确定${msg}"${tag_name}"标签吗？`, `该标签将在所有漫画中${msg}，有${comicCount}个漫画受到影响`, `${msg}中...`, (action, instance, done) => {
                return this.$api('setPublictagsStatus', { tag_id, show_status: show_status ^ 1 }).then(res => {
                    this.$message({ message: `${msg}"${tag_name}"分类成功`, type: 'success' });
                    this.getTagList(this.categoryNode.data.id, this.categoryNode.data.tag_name);
                    this.$emit('disableTag', { ...tag, show_status: tag.show_status ^ 1 });
                });
            }, { customClass: 'label-dialog' });
        },
        // 获取祖先分类id
        getParentsNodeId(node, needNode = false) {
            let startPrarentId = node.data.parent_id;
            const getNodeData = node => needNode ? node : node.data.id;
            const result = [getNodeData(node)];
            while (startPrarentId !== 0) {
                node = node.parent;
                result.unshift(getNodeData(node));
                startPrarentId = node.data.parent_id;
            }
            return result;
        },
        // 子类点击事件
        subCategoryClick(category) {
            this.$emit('categoryChange', category);
        },
        // 添加标签
        appendHandler(categoryNode) {
            let ids = [];
            if (categoryNode.data && categoryNode.data.id) ids = this.getParentsNodeId(categoryNode);
            this.changeDialog(true, '添加标签', 1, { ids, tag_name: '' });
        },
        // 选择标签事件
        selectTagHandler(tag, event) {
            if (!tag.show_status) return;
            if (this.singleClickTag) {
                clearTimeout(this.timer);
                this.singleClickTag = false;
                return;
            }
            this.singleClickTag = true;
            this.timer = setTimeout(() => {
                this.singleClickTag = false;
                const rootNode = this.breadcrumbList[0];
                tag.tag_id = tag.id;
                this.$emit('selectTag', { ...rootNode.data, tag });
            }, 300);
        },
        // 编辑回调
        editHandler(tag) {
            if (!this.isAdmin || !tag.show_status) return;
            const { id: tag_id, tag_name } = tag;
            const ids = this.getParentsNodeId(this.categoryNode);
            this.changeDialog(true, '编辑标签', 2, { ids, tag_id, tag_name });
        },
        // 取消修改
        cancleHandler() {
            this.$refs.tagForm.resetFields();
            this.dialogCloseHandle();
        },
        // 确认修改
        async confirmHandler() {
            const valid = await this.validateForm('tagForm');
            if (!valid) return ;
            const { type, data } = this.dialogData;
            const { tag_name, tag_id = null, ids } = data;
            const url = type === 1 ? 'addPublictags' : 'updatePublictags';
            const msg = type === 1 ? '新增' : '修改';
            let submitData = { tag_name, category_id: ids.slice(-1)[0] };
            if (type === 2) submitData.tag_id = tag_id;
            this.submitHandler(url, submitData, msg);
        },
        // 提交分类 新增、编辑
        submitHandler(url, data, msg) {
            this.$utils.confirm('', `确定${msg}"${data.tag_name}"标签吗？`, `${msg}中...`, (action, instance, done) => {
                this.isLoading = true;
                return this.$api(url, data).then(res => {
                    this.isLoading = false;
                    this.$message({ message: `${msg}"${data.tag_name}"标签成功`, type: 'success' });
                    this.getTagList(this.categoryNode.data.id, this.categoryNode.data.tag_name);
                    this.cancleHandler();
                });
            });
        },
        // 初始化拖拽标签
        initDrag() {
            if (!this.isAdmin || !this.currentCategoryStatus) return;
            this.dragTable = Sortable.create(this.$refs.tagContent, {
                sort: true,
                handle: '.tag-config-tag',
                onEnd: this.dragEndHandle
            });
        },
        // 拖拽结束
        dragEndHandle({ oldIndex, newIndex }) {
            if (oldIndex === newIndex) return false;
            if (newIndex === this.tagList.length) newIndex = this.tagList.length - 1;
            const tagListClone = this.$utils.cloneDeep(this.tagList);
            tagListClone.splice(newIndex, 0, tagListClone.splice(oldIndex, 1)[0]);
            this.tagList = tagListClone;
            this.isOrder = true;
        },
        // 保存排序
        saveOrderHandler() {
            this.$utils.confirm('', '确定保存排序吗？', '保存中...', (action, instance, done) => {
                const { id: category_id } = this.categoryNode.data;
                const tag_ids = this.tagList.map(tag => tag.id);
                this.isLoading = true;
                return this.$api('resortPublictags', { category_id, tag_ids }).then(res => {
                    this.isLoading = false;
                    this.$message({ message: '排序保存成功', type: 'success' });
                    this.getTagList(this.categoryNode.data.id, this.categoryNode.data.tag_name);
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .tag-config{
        height: 100%;
        position: relative;
        &-head {
            line-height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-l{
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        &-add{
            margin-left: 10px;
            width: 16px;
            height: 16px;
            cursor: pointer;
            line-height: 16px;
            text-align: center;
            border: 1px solid #000;
            border-radius: 50%;
        }
        &-desc{
            color: #ccc;
            margin-left: 10px;
            .el-icon-info{
                font-size: 16px;
                margin-right: 5px;
                vertical-align: middle;
            }
            span{
                vertical-align: middle;
            }
        }
        &-wrap{
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 6px;
            overflow: hidden;
        }
        &-inner{
            box-sizing: border-box;
            padding: 20px;
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: flex-start;
            overflow: auto;
            flex-grow: 1;
        }
        &-breadcrumb{
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
        }
        &-content{
            position: absolute;
            top: 32px;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-flow: column nowrap;
        }
        &-tag {
            margin: 5px;
            &-inner{
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                vertical-align: middle;
            }
            cursor: pointer;
            background-color: #fff;
            border-color: #DCDFE6;
            color: #606266;
            &:hover{
                background-color: #ecf5ff;
                border-color: #d9ecff;
                color: #409EFF;
            }
        }
        &-tree{
            padding: 5px 20px;
            max-height: 70px;
            overflow: auto;
            border-bottom: 1px solid #ccc;
            li{
                padding: 5px 0;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                &.category-child{
                    cursor: pointer;
                    &.disabled{
                        color: #ccc;
                    }
                }
            }
        }
        .tag-disabled{
            background: #ccc;
            color: #ffffff;
            &-btn{
                margin-left: 5px;
            }
        }
        .tag-selected{
            background: #ecf5ff;
            color: #409EFF;
        }
        .button-new-tag{
            margin: 5px;
        }
    }
    .ae-form{
        padding: 15px 20px;
    }
</style>
<style lang="scss">
    .label-dialog {
        .el-message-box__title span{
            font-size: 18px;
        }
        .el-message-box__content .el-message-box__message p{
            font-size: 12px;
        }
    }
    .breadcrumb-item .el-breadcrumb__inner{
        cursor: pointer;
    }
</style>
