<!--
 * @Author: daipeng
 * @Date: 2019-09-10 14:04:29
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-16 17:18:44
 * @Description: 漫画绑定的标签
 -->
<template>
    <div class="tag-result">
        <div class="tag-result-head">
            <h3>漫画已选标签</h3>
            <el-button size="mini" type="text" @click="removeAllHandler">移除所有</el-button>
        </div>
        <ul class="tag-result-wrap">
            <li class="tag-result-item" v-for="category in tagList" :key="category.id">
                <div v-if="category.tags.length" class="tag-result-categroy" :title="category.category_name">{{category.category_name}}</div>
                <div class="tag-result-tags">
                    <el-tag
                        :class="{'tag-result-tags-item': true, disabled: !tag.show_status }"
                        v-for="tag in category.tags" :key="tag.tag_id"
                        closable
                        size="medium"
                        :disable-transitions="false"
                        @close="removeHandler(category, tag)"
                    >
                        <span class="tag-result-tags-item-inner" :title="tag.tag_name">{{tag.tag_name}}</span>
                    </el-tag>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
let cloneTagList = [];

export default {
    props: {
        resultTagList: { type: Array, default: () => [] },
        selectedTag: { type: Object, default: () => {} }
    },
    data() {
        return {
            tagList: []
        };
    },
    created() {
        this.getTagList();
    },
    watch: {
        selectedTag(newTag, oldTag) {
            if (newTag.id) this.addTagHandler(newTag);
        },
        '$store.state.comic.refresh'() {
            this.getTagList();
        }
    },
    methods: {
        // 获取标签列表
        getTagList() {
            // const { comicId: comic_id } = this.$route.query;
            // this.$api('getComictags', { comic_id, show_status: -1, tag_name: '' }).then(res => {
            //     this.tagList = res.data || [];
            //     cloneTagList = this.$utils.cloneDeep(this.tagList);
            //     this.uploadResultTag(cloneTagList);
            // });
            this.tagList = this.$utils.cloneDeep(this.$store.state.comic.tagList) || [];
            cloneTagList = this.$utils.cloneDeep(this.tagList);
            this.uploadResultTag(cloneTagList);
        },
        // 本地配置禁用后需要查看是否有选中标签需要禁用
        localDisable(tag) {
            this.tagList.forEach(category => {
                const tagIndex = category.tags.findIndex(item => item.id === tag.id);
                if (tagIndex > -1) {
                    category.tags[tagIndex].show_status = tag.show_status;
                    category.tags = category.tags;
                }
            });
        },
        // 上传结果数据
        uploadResultTag(cloneTagList) {
            const tags = cloneTagList.reduce((arr, category) => {
                arr = arr.concat(category.tags);
                return arr;
            }, []);
            this.$emit('update:resultTagList', tags);
        },
        // 重置数据
        resetTagList() {
            this.tagList = cloneTagList;
        },
        // 增加标签方法
        addTagHandler(category) {
            const existCategory = this.tagList.find(item => item.id === category.id);

            if (existCategory) {
                const existTag = existCategory.tags.find(tag => tag.tag_id === category.tag.tag_id);
                if (!existTag) existCategory.tags.push(category.tag);
                else this.$message({ message: '漫画已存在该标签' });
            } else this.tagList.push({ ...category, tags: [category.tag] });
            this.uploadResultTag(this.$utils.cloneDeep(this.tagList));
        },
        removeHandler(category, tag) {
            category.tags.splice(category.tags.indexOf(tag), 1);
            this.uploadResultTag(this.$utils.cloneDeep(this.tagList));
        },
        // 移除所有选择标签
        removeAllHandler() {
            this.tagList = [];
            this.uploadResultTag(this.$utils.cloneDeep(this.tagList));
        }
    }
};
</script>

<style lang="scss" scoped>
.tag-result{
    height: 100%;
    position: relative;
    &-head {
        padding-right: 20px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-wrap{
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 6px;
        overflow: auto;
        padding: 10px 0;
        .tag-result-item{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            .tag-result-categroy{
                width: 80px;
                flex-basis: 80px;
                padding: 5px;
                line-height: 30px;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .tag-result-tags{
                flex: 1 0;
                display: flex;
                flex-flow: row wrap;
                align-content: flex-start;
                &-item{
                    &-inner{
                        max-width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    margin: 5px;
                    &.disabled{
                        background: #ccc;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
